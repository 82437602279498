@import '@core/scss/core.scss';
@import './assets/scss/styles';
.btn-primary {
    border-color: rgb(32, 92, 180) !important;
    background-color: rgb(32, 92, 180) !important;
    color: #fff !important;
}


/*a {
    color: rgb(32, 92, 180) !important;
    text-decoration: none;
    background-color: transparent;
}*/

.text-primary {
    color: rgb(32, 92, 180) !important;
}

.main-menu .navbar-header .navbar-brand .brand-text {
    color: rgb(32, 92, 180) !important;
    padding-left: 1rem;
    margin-bottom: 0;
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-size: 1.45rem;
    animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
}


/****LIGHT THEME***/

.main-menu.menu-light .navigation>li ul .active {
    background: linear-gradient(118deg, #205cb4, rgba(32, 92, 180, 0.7));
    box-shadow: 0 0 10px 1px rgba(32, 92, 180, 0.7);
    border-radius: 4px;
    z-index: 1;
}

.main-menu.menu-light .navigation>li.active>a {
    background: linear-gradient(118deg, #205cb4, rgba(32, 92, 180, 0.7));
    box-shadow: 0 0 10px 1px rgba(32, 92, 180, 0.7);
    color: #fff;
    font-weight: 400;
    border-radius: 4px;
}


/****END LIGHT THEME***/


/*** DARK THEME ****/

.main-menu.menu-dark .navigation>li ul .active {
    background: linear-gradient(118deg, #edc656, rgba(237, 198, 86, 0.7));
    box-shadow: 0 0 10px 1px rgba(237, 198, 86, 0.7);
    border-radius: 4px;
    z-index: 1;
    color: #000!important;
}

.main-menu.menu-dark .navigation>li.active>a {
    background: linear-gradient(118deg, #edc656, rgba(237, 198, 86, 0.7));
    box-shadow: 0 0 10px 1px rgba(237, 198, 86, 0.7);
    color: #000!important;
    font-weight: 400;
    border-radius: 4px;
}

.dark-layout .main-menu .collapse-toggle-icon {
    color: #edc656 !important;
}


/*** END DARK THEME ****/

.badge-primary {
    color: #fff;
    background-color: #205cb4!important;
}

.btn-primary {
    border-color: #205cb4!important;
    background-color: #205cb4!important;
    color: #fff !important;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a,
.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active:hover a {
    background-color: #205cb4!important;
    color: #fff !important;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 5px;
}

.brand-logo>h2 span {
    color: #205cb4 !important;
}

.btn-primary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #205cb4 !important;
}

.ng-select .ng-option.ng-option-selected.ng-option-marked {
    background-color: #205cb4 !important;
    color: #fff !important;
}

.ng-select .ng-option.ng-option-selected {
    background-color: #205cb4 !important;
    color: #fff !important;
}

.ng-select .ng-option.ng-option-marked {
    background-color: rgba(115, 103, 240, 0.12) !important;
    color: #205cb4 !important;
}

.ng-select.ng-select-multiple .ng-value {
    background-color: #205cb4 !important;
    color: #fff;
    border: none !important;
    font-size: 0.8rem !important;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
}

.auth-login-form>div a {
    color: #205cb4 !important;
    text-decoration: none;
    background-color: transparent;
}

.breadcrumb-item>a {
    color: #205cb4 !important;
}

input:focus {
    border: #205cb4 1px solid !important;
}

footer>p>span>a {
    color: #205cb4 !important;
}